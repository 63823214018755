.fullHeight {
  height: 100vh;
}
.center {
  display: flex;
  align-items: center;
}
.background {
  object-fit: cover;
}
